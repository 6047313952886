import React from "react"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout"
import Seo from "../components/seo"

const Downloads = ({ data }) => {
  const { t } = useTranslation()

  const categories = data.allStrapiCategory.nodes;

  return (
    <Layout className="bg-reallyDarkBlue">
      <Seo title={t("metaDownloadsTitle")} description={t("metaDownloadsDescription")} keywords={t("metaDownloadsKeywords")} />

      <div className="bg-reallyDarkBlue text-white relative">
        <div className="pt-10">
          <div className="max-w-7xl px-6 md:px-10 mx-auto text-center md:mb-28">
            <h1 className="text-4xl md:text-[4rem] w-max font-bold text-center mx-auto">
              {t("downloads")}
            </h1>
          </div>
          <div className="divide-y-[0.5px] divide-blue">
            <div>
              <div className="w-full md:flex max-w-7xl px-6 md:px-10 py-32 mx-auto">
                <div className="w-full md:w-1/2 pb-7">
                  <p className="text-white font-bold text-xl">{t("aboutUs")}</p>
                </div>
                <div className="w-full md:w-1/2 space-y-1">
                  <a href={t("generalDocumentPdfLink")} target="_blank" rel="noreferrer" className="block">
                    <span className="group flex justify-between text-darkGray py-4 font-bold uppercase">
                      <span className="block text-lg border-b border-transparent group-hover:border-purple group-hover:text-purple">
                        {t("catalog")}
                      </span>
                      <span className="inline-flex items-center justify-center cursor-pointer h-auto max-w-[24px] w-full rounded-full">
                        <svg
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="fill-current text-white"
                        >
                          <path d="M6.8 1.20002C6.8 0.758197 6.44183 0.400024 6 0.400024C5.55817 0.400024 5.2 0.758197 5.2 1.20002L6.8 1.20002ZM5.43432 12.9657C5.74673 13.2781 6.25327 13.2781 6.56569 12.9657L11.6569 7.87454C11.9693 7.56212 11.9693 7.05559 11.6569 6.74317C11.3444 6.43075 10.8379 6.43075 10.5255 6.74317L6 11.2687L1.47452 6.74317C1.1621 6.43075 0.655565 6.43075 0.343146 6.74317C0.0307266 7.05559 0.0307266 7.56212 0.343146 7.87454L5.43432 12.9657ZM5.2 1.20002L5.2 12.4L6.8 12.4L6.8 1.20002L5.2 1.20002Z" />
                        </svg>
                      </span>
                    </span>
                  </a>
                  {/* <a className="block">
                    <div className="group flex justify-between text-darkGray py-4 font-bold uppercase">
                      <p className="text-lg border-b border-transparent group-hover:border-purple group-hover:text-purple">
                        {t("howWeWork")}
                      </p>
                      <div className="inline-flex items-center justify-center cursor-pointer h-auto max-w-[24px] w-full rounded-full">
                        <svg
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="fill-current text-white"
                        >
                          <path d="M6.8 1.20002C6.8 0.758197 6.44183 0.400024 6 0.400024C5.55817 0.400024 5.2 0.758197 5.2 1.20002L6.8 1.20002ZM5.43432 12.9657C5.74673 13.2781 6.25327 13.2781 6.56569 12.9657L11.6569 7.87454C11.9693 7.56212 11.9693 7.05559 11.6569 6.74317C11.3444 6.43075 10.8379 6.43075 10.5255 6.74317L6 11.2687L1.47452 6.74317C1.1621 6.43075 0.655565 6.43075 0.343146 6.74317C0.0307266 7.05559 0.0307266 7.56212 0.343146 7.87454L5.43432 12.9657ZM5.2 1.20002L5.2 12.4L6.8 12.4L6.8 1.20002L5.2 1.20002Z" />
                        </svg>
                      </div>
                    </div>
                  </a> */}
                  <a href={t("accessoriesDocumentPdfLink")} target="_blank" rel="noreferrer" className="block">
                    <span className="group flex justify-between text-darkGray py-4 font-bold uppercase">
                      <span className="block text-lg border-b border-transparent group-hover:border-purple group-hover:text-purple">
                        {t("accessories")}
                      </span>
                      <span className="inline-flex items-center justify-center cursor-pointer h-auto max-w-[24px] w-full rounded-full">
                        <svg
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="fill-current text-white"
                        >
                          <path d="M6.8 1.20002C6.8 0.758197 6.44183 0.400024 6 0.400024C5.55817 0.400024 5.2 0.758197 5.2 1.20002L6.8 1.20002ZM5.43432 12.9657C5.74673 13.2781 6.25327 13.2781 6.56569 12.9657L11.6569 7.87454C11.9693 7.56212 11.9693 7.05559 11.6569 6.74317C11.3444 6.43075 10.8379 6.43075 10.5255 6.74317L6 11.2687L1.47452 6.74317C1.1621 6.43075 0.655565 6.43075 0.343146 6.74317C0.0307266 7.05559 0.0307266 7.56212 0.343146 7.87454L5.43432 12.9657ZM5.2 1.20002L5.2 12.4L6.8 12.4L6.8 1.20002L5.2 1.20002Z" />
                        </svg>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div className="w-full md:flex max-w-7xl px-6 md:px-10 md:px-15 lg:px-20 py-32 mx-auto ">
                <div className="w-full md:w-1/2 pb-7">
                  <p className="text-white font-bold text-xl">{t("beltsDocTitle")}</p>
                </div>
                <div className="w-full md:w-1/2 space-y-1">
                 {categories.map(category => (
                  <a key={category.title} href={category.documentation[0]?.url} target="_blank" rel="noreferrer" className="block">
                    <span className="group flex justify-between text-darkGray py-4 font-bold uppercase">
                      <span className="block text-lg border-b border-transparent group-hover:border-purple group-hover:text-purple">
                         {category.title}
                      </span>
                      <span className="inline-flex items-center justify-center cursor-pointer h-auto max-w-[24px] w-full rounded-full">
                        <svg
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="fill-current text-white"
                        >
                          <path d="M6.8 1.20002C6.8 0.758197 6.44183 0.400024 6 0.400024C5.55817 0.400024 5.2 0.758197 5.2 1.20002L6.8 1.20002ZM5.43432 12.9657C5.74673 13.2781 6.25327 13.2781 6.56569 12.9657L11.6569 7.87454C11.9693 7.56212 11.9693 7.05559 11.6569 6.74317C11.3444 6.43075 10.8379 6.43075 10.5255 6.74317L6 11.2687L1.47452 6.74317C1.1621 6.43075 0.655565 6.43075 0.343146 6.74317C0.0307266 7.05559 0.0307266 7.56212 0.343146 7.87454L5.43432 12.9657ZM5.2 1.20002L5.2 12.4L6.8 12.4L6.8 1.20002L5.2 1.20002Z" />
                        </svg>
                      </span>
                    </span>
                  </a>
                 ))}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-28 w-full">
            <StaticImage
              src="../images/downloads.png"
              formats={["auto", "webp", "avif"]}
              alt="downloads"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Downloads

export const query = graphql`
  query ($language: String!) {
    allStrapiCategory(filter: {locale: {eq: $language}}) {
      nodes {
        id
        title
        documentation {
          alternativeText
          url
          size
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`